<template>
  <div class="tagesarbeit">
    <va-card>
      <h5 class="mt-0 mb-0">{{ $t('tagesarbeit.title') }}</h5>
      <div class="row">
        <va-notification class="flex md10" v-if="currState.msgType=='success'" color="success">
          <va-badge color="success">
            {{ $t('common.notificationSuccess') }}
          </va-badge>
          {{ currState.msg }}
        </va-notification>
        <va-notification class="flex md10" v-if="currState.msgType=='error'" color="danger">
          <va-badge color="danger">
            {{ $t('common.notificationError') }}
          </va-badge>
          {{ currState.msg }}
        </va-notification>
      </div>
      <div class="row">
        <FunctionalCalendar
          class="flex md3 mr-4"
          v-model="calData"
          :is-modal="false"
          :is-multiple-date-picker="true"
          :date-format="'dd.mm.yyyy'"
          :show-week-numbers="true"
          :day-names="['Mo','Di','Mi','Do','Fr','Sa','So']"
          :disabled-day-names="['So']"
          :month-names="['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember']"
          :is-layout-expandable="true"
          @choseDay="calcSelectedDays()"
        ></FunctionalCalendar>
        <div class="flex md8">
          <div class="row">
            <div>gewählte Daten:</div>
          </div>
          <div class="row">
            <div>&nbsp;{{ this.newData.selectedDays }}<br>&nbsp;<br></div>
          </div>
          <div class="row align--center">
            Uhrzeit für alle gewählten Tage:
            <va-input class="flex md1 ml-2" v-model="newData.eventStartTime" />
            <va-input class="flex md1" v-model="newData.eventEndTime" />
          </div>
          <div class="row align--center">
            {{ $t('anmelden.subscribeWith') }}:
            <va-button small color="info" @click="signupDecr(newData)" class="mr-3 pr-2 pl-2">-</va-button>
            <b>{{ newData.workersCount }}</b>
            <va-button small color="info" @click="signupIncr(newData)" class="ml-3 mr-3 pr-2 pl-2">+</va-button>
            {{ $t('anmelden.persons') }}
          </div>
          <div class="row align--center">
            <div>{{ $t('anmelden.bookOtherUser') }}</div>
            <va-input class="flex"  v-model="newData.otherUser" :placeholder="currentUser" />
          </div>
          <div class="row">
            <va-input class="flex"  v-model="newData.comments" :placeholder="$t('anmelden.comments')" />
            <va-button small color="info" @click="saveMyDayEvent(newData)"> {{ $t('anmelden.signUp') }}</va-button>
          </div>
        </div>
      </div>
    </va-card>
    <va-modal v-model="toDelete.showDeleteModal"
      :message="toDelete.msg"
      :title="$t('anmelden.signoutTitle')"
      :ok-text="$t('common.yes')"
      :cancel-text="$t('common.no')"
      @ok="deleteMyDayEvent()"/>
    <div class="row" v-for="(ev,evK) in dayEventList" :key="evK">
      <div class="flex md12">
        <va-card >
          <div class="row">
            <va-input class="flex" v-model="ev.selectedDays" @input="setEventModified(ev)" readonly />
          </div>
          <div class="row align--center">
            <va-input class="flex md1" v-model="ev.eventStartTime" @input="setEventModified(ev)" />
            <va-input class="flex md1" v-model="ev.eventEndTime" @input="setEventModified(ev)" />
            <va-input class="flex" v-model="ev.otherUser" :placeholder="currentUser" @input="setEventModified(ev)" />
            <div>
              {{ $t('anmelden.subscribeWith') }}:
              <va-button small v-if="ev.workersCount" color="info" @click="signupDecr(ev)" class="mr-3 pr-2 pl-2">-</va-button>
              <b>{{ ev.workersCount }}</b>
              <va-button small v-if="ev.workersCount" color="info" @click="signupIncr(ev)" class="ml-3 mr-3 pr-2 pl-2">+</va-button>
              {{ $t('anmelden.persons') }}
            </div>
          </div>
          <div class="row">
            <va-input class="flex" v-model="ev.comments" :placeholder="$t('anmelden.comments')" @input="setEventModified(ev)" />
            <div class="flex md1">
              <va-button small v-if="ev.isDirty"  @click="saveMyDayEvent(ev)"> {{ $t('common.save') }}</va-button>
            </div>
            <div class="flex">
              <va-button small v-if="ev.workersCount" color="danger" @click="askDeleteEvent(ev)"> {{ $t('anmelden.signOut') }}</va-button>
            </div>
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

import { FunctionalCalendar } from 'vue-functional-calendar'
// import { Datetime } from 'vue-datetime'

export default {
  name: 'tagesarbeit',
  components: {
    FunctionalCalendar,
  },
  data () {
    this.getEvents()
    return {
      dayEventList: {},
      calData: {},
      newData: {
        dayEventID: -1,
        eventStartTime: '17:00',
        eventEndTime: '20:00',
        workersCount: 1,
        otherUser: '',
        selectedDays: '',
        comments: '',
      },
      currState: {
        msgType: '',
        msg: '',
      },
      toDelete: {
        showDeleteModal: false,
        msg: '',
        dayEventID: -1,
      },
      currentUser: this.$userauth.loggedInUser(),
    }
  },
  methods: {
    initNewData () {
      this.newData = {
        dayEventID: -1,
        eventStartTime: '17:00',
        eventEndTime: '20:00',
        workersCount: 1,
        otherUser: '',
        selectedDays: '',
        comments: '',
      }
      this.calData.selectedDates = []
    },
    mounted () {
    },
    getEvents () {
      this.$srvconn.sendMessage('getMyDayEvents')
        .then(response => {
          if (response.msgType === 'getMyDayEvents') {
            this.dayEventList = response.userData.dayEvents
          }
          // console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveMyDayEvent (ev) {
      var usrData = {
        dayEventID: ev.dayEventID,
        eventStartTime: ev.eventStartTime,
        eventEndTime: ev.eventEndTime,
        workersCount: ev.workersCount,
        otherUser: ev.otherUser,
        selectedDays: ev.selectedDays,
        comments: ev.comments,
      }
      this.$srvconn.sendMessage('saveMyDayEvent', usrData)
        .then(response => {
          if (response.msgType === 'error') {
            this.currState.msgType = 'error'
            this.currState.msg = response.userData
          } else {
            this.currState.msgType = 'success'
            this.currState.msg = this.$t('common.saveSuccess') + ' ' + response.userData.okMsg
            if (ev.dayEventID === -1) {
              this.getEvents()
            }
            this.initNewData()
            ev.isDirty = false
          }
        })
        .catch(err => {
          this.currState.msgType = 'error'
          this.currState.msg = err
        })
    },
    setEventModified (ev) {
      ev.isDirty = true
    },
    askDeleteEvent (ev) {
      this.toDelete.msg = ev.selectedDays + ' ' + ev.eventStartTime + '-' + ev.eventEndTime + '  -->  ' + this.$t('anmelden.reallySignout')
      this.toDelete.userEvent = ev
      this.toDelete.showDeleteModal = true
    },
    deleteMyDayEvent () {
      if (this.toDelete.userEvent) {
        var usrData = {
          dayEventID: this.toDelete.userEvent.dayEventID,
        }
        this.$srvconn.sendMessage('deleteMyDayEvent', usrData)
          .then(response => {
            if (response.msgType === 'error') {
              this.currState.msgType = 'error'
              this.currState.msg = response.userData
            } else {
              this.currState.msgType = 'success'
              this.currState.msg = this.$t('common.saveSuccess')
              this.toDelete.userEvent.commments = ''
              this.toDelete.userEvent.workersCount = 0
              this.toDelete.userEvent = null
              this.toDelete.msg = ''
              this.getEvents()
            }
          })
          .catch(err => {
            console.log('ERROR: ' + err)
            this.currState.msgType = 'error'
            this.currState.msg = err
          })
      }
    },
    signup (ev) {
      ev.workersCount = 1
      this.saveMyDayEvent(ev)
    },
    signupIncr (ev) {
      ev.workersCount++
      ev.isDirty = true
    },
    signupDecr (ev) {
      if (ev.workersCount > 1) {
        ev.workersCount--
        ev.isDirty = true
      }
    },
    calcSelectedDays () {
      this.newData.selectedDays = ''
      this.calData.selectedDates.forEach(selD => {
        if (this.newData.selectedDays !== '') this.newData.selectedDays += ', '
        this.newData.selectedDays += selD.date
      })
    },
  },
}
</script>
